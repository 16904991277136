<template>
  <div class="get-rank">

<!-- <div v-if="field1" class="alert alert-info">
    Current Value: {{field1}}
</div> -->

<place-autocomplete-field
    v-model="field2"
    name="field2"
    api-key="AIzaSyBlL_mIguKK-6uuF22avVhe5QYsjiZK1B0"
    placeholder="Enter an address, zipcode, or location"
    language="en"
    v-place-autofill:street="street"
    v-place-autofill:sublocality="sublocality"
    v-place-autofill:locality="locality"
    v-place-autofill:zipcode="zip"
    v-place-autofill:city="city"
    v-place-autofill:state="state"
    v-place-autofill:county="county"
    v-place-autofill:country="country"
    v-place-autofill:latitude="latitude"
    v-place-autofill:longitude="longitude"
    @autocomplete-select="results"
    >
</place-autocomplete-field>

<!-- <div class="card w-30">
    <div class="card-body"> -->
        <!-- <p><b class="card-title">Enter an address above and watch the fields below get auto-filled...</b></p> -->
        <!-- <p>
            <label>latitude</label><br>
            <input name="latitude" v-model="latitude" class="form-control"/>
        </p>
        <p>
            <label>longitude</label><br>
            <input name="longitude" v-model="longitude" class="form-control"/>
        </p>
        <p>
            <label>sublocality</label><br>
            <input name="sublocality" v-model="sublocality" class="form-control"/>
        </p>
        <p>
            <label>locality</label><br>
            <input name="locality" v-model="locality" class="form-control"/>
        </p> -->

        <!-- <p>
            <label>Street</label><br>
            <input name="street" v-model="street" class="form-control"/>
        </p>
        <p>
            <label>Zip</label><br>
            <input name="zip" v-model="zip" class="form-control"/>
        </p>
        <p>
            <label>County</label><br>
            <input name="county" v-model="county" class="form-control"/>
        </p>
        <p>
            <label>City</label><br>
            <input name="city" v-model="city" class="form-control"/>
        </p> -->
        
        <!-- <p>
            <label>State</label><br>
            <input name="state" v-model="state" class="form-control"/>
        </p>
        <p>
            <label>Country</label><br>
            <input name="country" v-model="country" class="form-control"/>
        </p> -->

    <!-- </div>
</div> -->


  </div>
</template>

<script>
export default {
    name: 'GetRank',
    data () {
        return {
            field1: '',
            field2: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            county: '',
            sublocality: '',
            locality: '',
            longitude: '',
            latitude: '',
        }
    },
    methods: {
        results(place, address) {

            const locations = []
            place.terms.forEach(x => {
                return locations.push(x.value)
            })
            const result = {
                placeRef: place.reference,
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng(),
                locs: locations 
            }
            this.$emit('rankAreas', result)
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
.autocomplete-list-item-label {
    color: black;
}
</style>