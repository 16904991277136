<template>
    <div id="settings-links">
        
        <div v-for="(val, key, i) in getLinks" :key="i">
            <div class="d-f m-b">
                <div class="input-container w-100 m-r">
                    <label class="label" :for="val">{{key}}</label>
                    <input type="text" :id="val" :name="val" :placeholder="val" disabled/>
                </div>
                <button class="d-f jc-c ai-c" @click="deleteLink(val, key)">
                    <IconBase w="16" h="16" color="#000" icon-name="cancel"><IconCancel/></IconBase>
                </button>
                
            </div>
        </div>

        <div class="d-f">
            <div class="input-container m-r w-100">
            <label class="label" for="link">Add Link</label>
            <input type="text" id="link" name="link" v-model="link" placeholder="Add Link" />
            </div>
            <div class="input-container w-100">
            <label class="label" for="title">Link Title</label>
            <input type="text" id="title" name="title" v-model="title" placeholder="Link Title" />
            </div>
        </div>
        <div class="d-f jc-fe m-t" v-show="link">
          <button class="m-r" @click="link = '', title = ''">Cancel</button>
          <button @click="saveLink">Save Link</button>
        </div>
    
    </div>
</template>
<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import IconBase from '@/assets/icons/IconBase.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

export default {
  name: 'SettingsLinks',
  components: {
      IconBase,
      IconCancel
  },
  data() {
    return {
        link: '',
        title: '',
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings'
    ]),
    getLinks() {
        if(this.getUserSettings.links) {
            return this.getUserSettings.links
        } else {
            return false
        }
    }
  },
  methods: {
    saveLink() {
        if (this.link.length > 0 && this.title.length > 0) {
            const uid = this.getUser.data.uid
            const linksRef = rtdb.ref('userSettings').child(uid).child('links')
            linksRef.update({
                [this.title]: this.link
            }).then(() => {
                console.log('Link added')
                // this.$router.go()
            }).catch((err) => { console.log(err) })
        } else {
            alert('Link and title field empty')
        }
    },
    deleteLink(val, key) {
        const uid = this.getUser.data.uid
        const linksRef = rtdb.ref('userSettings').child(uid).child('links')
        linksRef.child(key).remove().then(() => {
            console.log('Link removed')            
            this.$router.go()
        }).catch((err) => { console.log(err) })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
</style>
