<template>
  <div id="upload-img" class="m-b-children d-f jc-c ai-c fd-c">

    <div class="file-upload" v-if="getPicture">
      <label class="custom-input d-f ai-c img-medium" for="file-upload">
          <div v-if="imageData == null">
          <img style="border-radius: 100%;" v-if="picture != null" :src="picture">
          <div v-else><PlaceholderImg/></div>
          </div>

          <p class="ta-c tt-up m-t font-small" v-if="imageData == null">
            <span v-if="!picture">Upload profile img (max 3mb)</span>
            <span v-if="picture">Change profile img (max 3mb)</span>
          </p>

          <div class="file-name d-f fd-c jc-c ai-c" v-if="imageData != null">
            <p>{{imageData.name}}</p>
            <p v-if="uploadValue">
              Uploading: {{ uploadValue.toFixed() + "%" }}
                <!-- <progress id="progress" :value="uploadValue" max="100" ></progress> -->
            </p>
          </div>

      </label>
      <input id="file-upload" type="file" @change="previewImage" accept="image/png, image/gif, image/jpeg, image/jpg" >
    </div>
    <div v-if="imageData != null">
      <button @click="onUpload()">Upload file</button>
    </div>

  </div>
</template>

<script>
import { rtdb, storage } from '@/db.js'
import { mapGetters } from "vuex"
import PlaceholderImg from '@/assets/icons/PlaceholderImg.vue'

export default {
  name: 'UploadImg',
	components: {
		PlaceholderImg,
	},
  data() {
	  return {
      imageData: null,
      picture: null,
      uploadValue: 0
	  }
  },
  // mounted() {
  //   this.getPicture
  // },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings'
    ]),
    getPicture() {
      if(this.getUserSettings.profileImg) {
        this.picture = this.getUserSettings.profileImg
        return true
      } else {
        this.picture = null
        return true
      }
    }
  },

  methods:{
    previewImage(event) {
      this.uploadValue = 0
      this.picture = null
      this.imageData = event.target.files[0]
    },
    onUpload(){
      this.picture = null

      if(this.imageData.size > 3250586){
        alert("File is too big!");
      } else {
        const usersRef = rtdb.ref('userSettings').child(this.getUser.data.uid)
        // const storageRef = storage.ref(`userSettings/${this.getUser.data.uid}/profileimages/${this.imageData.name}`).put(this.imageData)
        const storageRef = storage.ref(`userSettings/${this.getUser.data.uid}/profileimages/ProfileImg`).put(this.imageData)
        storageRef.on(`state_changed`, 
          (snapshot) => {
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes) * 100
          }, 
          (error) => { console.log(error.message) },
          () => { this.uploadValue = 100
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.picture = url
              this.imageData = null
              usersRef.update({
                  'profileImg': url
              }).then(() => {
                console.log('Profile Image changed')
              }).catch((err) => { console.log(err) })
          }).catch((err) => { console.log(err) })
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
</style>