<template>
  <div id="settings-rank" class="m-b">
    <GetRank class="w-100 m-b" v-if="showGetRankData" @rankAreas="getAreas($event)"/>
    <div class="d-f jc-sb">
      <div class="input-container w-100">
        <label class="label" for="rank-area">Rank Area</label>
        <input type="text" id="rank-area" name="rank-area" v-model="rankInput" placeholder="Set your ranking area" disabled/>
      </div>
      <div v-if="!showGetRankData" class="m-l d-f jc-c">
        <button @click="showGetRank()">Edit</button>
        <!-- <button v-else @click="cancelRank()">Cancel</button> -->
      </div>
    </div>

    <!-- <div class="d-f jc-sb" v-if="showGetRankData">
      <GetRank class="w-100" @rankAreas="getAreas($event)"/>
      <button class="m-l d-f jc-c ws-pre" v-show="newRank" @click="saveRank()"><span class="ws-pre">Save Rank</span></button>
    </div> -->

    <div v-show="showGetRankData">
      <div class="d-f jc-fe m-t">
        <!-- <div class="font-small m-r">NOTE: After you save, the new rank location will update first after 1 hour.</div> -->
        <div>
        <button @click="cancelRank()">Cancel</button>
        </div>
        <div>
        <button class="m-l ws-pre" v-show="newRank" @click="saveRank()">Save Rank</button>
        </div>
      </div>
    </div>

    <!-- <div v-show="showGetRankData">
      <GetRank @rankAreas="getAreas($event)"/>
      <div v-show="newRank">
        <button @click="cancelRank()">Cancel</button>
        <button @click="saveRank()">Save Rank</button>
      </div>
    </div> -->
  </div>
</template>
<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import GetRank from '@/components/settings/GetRank.vue'

export default {
  name: 'SettingsRank',
  components: {
    GetRank
  },
  data() {
    return {
      // oldRank: ['Old rank...'],
      newRank: null,
      newRankPlaceRef: null,
      showGetRankData: false
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings'
    ]),
    rankInput() {
      if (this.newRank) {
        const rankLocations = this.newRank.locs
        const values = Object.values(rankLocations)
        const string = values.join(', ')
        return string
      } else {
        const rankLocations = this.getUserSettings.locations
        const values = Object.values(rankLocations)
        const string = values.join(', ')
        return string
      }
    },
    oldRankPlaceRef() {
      return this.getUserSettings.locationInfo.placeRef
    }
  },
  methods: {
    showGetRank() {
      this.showGetRankData = !this.showGetRankData
    },
    getAreas(x) {
      this.newRank = x
      this.newRankPlaceRef = x.placeRef
    },
    saveRank() {
        if (this.newRank != null) {
            if (this.newRankPlaceRef != this.oldRankPlaceRef) {
              const arr = this.newRank.locs
              const revArr = arr.reverse()
              const obj = { loc0: 'World' }
              revArr.forEach((x, i) => {
                  return obj['loc' + (i+1)] = x
              })
              rtdb.ref('userSettings').child(this.getUser.data.uid)
              .update({
                  locations: obj,
                  locationInfo: {
                      placeRef: this.newRank.placeRef,
                      lat: this.newRank.lat,
                      lng: this.newRank.lng
                  }
              }).then(() => { 
                  console.log('Rank area in userSettings updated')
                  //This is a quickfix to the update bug
                  this.$router.push('/')
              }).then(() => { 
                  //This is a quickfix to the update bug
                  location.reload()
                  alert('NOTE: The new rank location will update after 1 hour.')
                  // this.$store.dispatch('togglePopMess', 'The new rank location will update after 1 hour.')
              })
            } else { alert('Ops, new rank same as old rank...') }
        } else { alert('Ops, new rank area missing...') }
    },
    cancelRank() {
        this.newRank = null
        this.showGetRankData = false
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
</style>
