<template>
  <div id="settings-user" class="big-m-t big-m-b d-f jc-c p">
    <div class="small-wrap">

      <UploadImg/>
      
      <SettingsRank v-if="renderComponent"/>

      <form class="d-f fd-c m-b-children">

        <div class="input-container">
          <label class="label" for="username">Username</label>
          <input type="text" id="username" name="username" 
          :value="getUsername" 
          @input="changeUsername($event)" 
          placeholder="Username"/>
        </div>
        <div class="d-f jc-fe" v-show="showSaveUsername">
          <button class="m-r" @click="showSaveUsername = false">Cancel</button>
          <button @click.prevent="saveUsername">Save Username</button>
        </div>

        <div class="input-container">
          <label class="label" for="member">Member</label>
          <input type="text" id="member" name="member" v-model="member" placeholder="Member" disabled/>
        </div>

        <SettingsLinks/>

        <div class="input-container">
          <label class="label" for="email">Email</label>
          <input type="text" id="email" name="email" v-model="email" placeholder="Email" disabled/>
        </div>

        <div class="input-container">
          <label class="label" for="change-pass">New Password</label>
          <input type="password" id="change-pass" name="change-pass" v-model="newPassword" placeholder="New Password"/>
        </div>
        
        <div class="input-container" v-show="newPassword.length > 0">
          <label class="label" for="confirm-pass">Current Password</label>
          <input type="password" id="confirm-pass" name="confirm-pass" v-model="currentPassword" placeholder="Confirm Current Password"/>
        </div>
        <div class="d-f jc-fe" v-show="newPassword.length > 0">
          <button class="m-r" @click="newPassword = ''">Cancel</button>
          <button @click.prevent="savePassword">Save Password</button>
        </div>

      </form>

<div class="d-f fd-c m-b-children">
      <button @click="logout()">Log Out</button>
      <button class="" @click="showDeleteOption = true">Delete Account</button>

      <div class="" v-if="showDeleteOption">
        <p class="">Confirm password to delete this account</p>
        <div class="">
          <input class="" type="password" v-model="deleteAccPassword" placeholder="Password"/>
          <button class="" @click="deleteAccount">Delete</button>
          <button class="" @click="showDeleteOption = false">Cancel</button>
        </div>
      </div>
</div>
      
    </div>
  </div>
</template>
<script>
import { fb, auth, rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import UploadImg from '@/components/settings/UploadImg.vue'
import SettingsRank from '@/components/settings/SettingsRank.vue'
import SettingsLinks from '@/components/settings/SettingsLinks.vue'

export default {
  name: 'SettingsUser',
  components: {
    UploadImg,
    SettingsRank,
    SettingsLinks
  },
  data() {
    return {
      newUsername: null,
      showDeleteOption: false,
      deleteAccPassword: '',
      currentPassword: '',
      newPassword: '',
      showSaveUsername: null,
      renderComponent: true,
    }
  },
  watch: {
    userSettings (newVal, oldVal) {
      this.forceRerender()
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings'
    ]),
    userSettings () {
      return this.getUserSettings
    },
    member () {
        return 'Member#' + this.getUserSettings.member
    },
    email () {
      if(this.getUser.loggedIn) {
        return this.getUser.data.email
      } else {
        return
      }
    },
    getUsername() {
        return this.getUserSettings.username
    },

  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    changeUsername(event) {
      if(event.target.value != this.getUserSettings.username) {
        // console.log(event.target.value);
        this.newUsername = event.target.value
        this.showSaveUsername = true
      } else {
        this.newUsername = null
        this.showSaveUsername = false
      }
    },
    saveUsername() {
      const currentUsername = this.getUsername
      const newUsername = this.newUsername
      if (newUsername != null) {
        if (newUsername != currentUsername) {

          const uid = this.getUser.data.uid
          const usernamesRef = rtdb.ref('usernames')
          const userSettingsRef = rtdb.ref('userSettings').child(uid)
          const encodedUsername = encodeURIComponent(newUsername).replace(/\./g, '%2E')

          usernamesRef.update({
            [encodedUsername]: uid
          })
          .then(() => {
            console.log('Username updated')
            usernamesRef.child(this.getUserSettings.usernameUrl).remove()
            userSettingsRef.update({
              'username': newUsername,
              'usernameUrl': encodedUsername,
            })
          }).then(() => {
            this.$router.go()
            //Important to reload to reset currentUsername
          }).catch((err) => { console.log(err) })

        }
      } else {
        console.log('Nothing to save here.')
      }
    },
    savePassword() {
      if (this.newPassword.length > 1) {
        const user = auth.currentUser
        const newPass = this.newPassword
        const credential = fb.auth.EmailAuthProvider.credential(
            user.email, 
            this.currentPassword
        )
        // Prompt the user to re-provide their sign-in credentials
        user.reauthenticateWithCredential(credential).then(function() {
          console.log('User re-authenticated.')
          user.updatePassword(newPass).then(function() {
            console.log('Update password successful')
            this.$router.go()
          })
        }).catch(function(error) {
          alert(error.message)
        });
      } else {
        console.log('Nothing to save here.')
      }
    },
    deleteAccount () {
      var user = auth.currentUser
      var username = this.getUserSettings.usernameUrl
      console.log(username);
      const credential = fb.auth.EmailAuthProvider.credential(
          user.email, 
          this.deleteAccPassword
      )
      // Prompt the user to re-provide their sign-in credentials

      user.reauthenticateWithCredential(credential).then(() => {
        console.log('User re-authenticated.')
        rtdb.ref('usernames')
        .child(username)
        .remove()
        .then(() => {
          console.log('username removed.');
        })
        rtdb.ref('userSettings')
        .child(user.uid)
        .remove()
        .then(() => {
          rtdb.ref('userMusic')
          .child(user.uid)
          .remove()
          .then(() => {
            rtdb.ref('rankProfiles')
            .child(user.uid)
            .remove()
            .then(() => {
            }).catch(function(error) { console.log(error) })
          }).catch(function(error) { console.log(error) })
        })
        .then(() => {
          //Deleting user
          user
          .delete()
          .then(() => {
            console.log('User DELETED.')
            this.$router.push('/')
          }).then(() => {
            location.reload();
          })
          .catch(function(error) { console.log(error) })
        }).catch(function(error) { console.log(error) })
      }).catch(function(error) {
        console.log(error)
        alert(error.message)
      })
    },
    logout () {
        return this.$store.dispatch('logout')
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
</style>
